import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { intercom } from '../../lib/intercom'
import { useAuthContext } from '../Authentication/hooks/useAuthContext'
import { AppBar } from '../UI/AppBar/AppBar'

export const Main = () => {
    const { user } = useAuthContext().user

    useEffect(() => {
        if (!user) {
            return
        }
        intercom.init(user)
    }, [user])

    return (
        <div className="fixed w-screen h-full flex flex-col">
            <AppBar />
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
                <Outlet />
            </div>
        </div>
    )
}
