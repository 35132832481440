import classNames from 'classnames'

interface Props {
    className?: string
    seconds: number
}

export const DurationLabel = ({ className, seconds }: Props) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const parts = []
    if (hours > 0) parts.push(`${hours}hr`)
    if (minutes > 0) parts.push(`${minutes}min`)
    if (remainingSeconds > 0) parts.push(`${remainingSeconds}sec`)

    return (
        <div className={classNames(className, 'inline-block')}>
            {parts.join(' ')}
        </div>
    )
}
