import { useEffect, useState } from 'react'

import { JOIN_FULENT_EMAIL } from '../../../constants'
import { validationService } from '../../../services/validation.service'
import SendIcon from '../../UI/Icons/SendIcon'

interface Props {
    inviteLink?: string
}

const InviteByEmailCard = ({ inviteLink }: Props) => {
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (email.length > 0 && validationService.isEmail(email)) setDisabled(false)
        else setDisabled(true)
    }, [email, setDisabled])

    const handleSendInvite = () => {
        if (!inviteLink) return

        if (email && validationService.isEmail(email)) {
            const [subject, body] = JOIN_FULENT_EMAIL(inviteLink)
            const mailtoLink = `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
            window.location.href = mailtoLink
        }
    }

    return (
        <div className="w-full md:w-1/2 p-2">
            <div className="border flex flex-col p-4 rounded-md">
                <p className="font-bold mb-2">Invite by email</p>
                <div className="flex gap-2 items-center">
                    <input
                        type="email"
                        value={email}
                        disabled={!inviteLink}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        placeholder="name@email.com"
                        className="w-full border px-3 py-2 rounded-md text-gray-600"
                    />
                    <button
                        onClick={handleSendInvite}
                        disabled={disabled}
                        className="bg-primary text-white border disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 px-2 py-2 flex items-center justify-center gap-1 transition-all rounded-md disabled:hover:bg-gray-50 "
                    >
                        <SendIcon fill={disabled ? '#9ca3af' : '#fff'} />
                        Send
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InviteByEmailCard
