import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useRoute } from '../../hooks/useRoute'
import { postAudioComplete } from '../../lib/Whisper'
import { OpenConfirmationModal } from '../../services/event-bus/events'
import { useDeveloperContext } from '../Settings/Developer/hooks/useDeveloperContext'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'

interface Props {
    className?: string
    visitsGroupTitle: string
    visitsIds: string[]
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const DeleteVisitsByGroup = ({
    className,
    visitsGroupTitle,
    visitsIds,
    childNode,
}: Props) => {
    const { navVisits } = useRoute()
    const { isDeleteVisitLoading, deleteVisits } = useVisitsContext().visits
    const { isAwsTranscriber } = useDeveloperContext().developer

    const visitsCount = useMemo(() => visitsIds.length, [visitsIds.length])

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            OpenConfirmationModal.emit({
                titleNode: 'Deleting visits',
                iconNode: (
                    <ExclamationTriangleIcon className="h-10 w-10 text-red mr-4" />
                ),
                bodyNode: (
                    <>
                        <p>
                            You are about to delete{' '}
                            <span className="font-bold">{`${visitsCount} ${
                                visitsCount > 1 ? 'visits' : 'visit'
                            } from ${visitsGroupTitle}`}</span>
                            . This action cannot be undone.
                        </p>
                        <p className="mt-3">Do you want to continue?</p>
                    </>
                ),
                confirmLabel: 'Permanently delete',
                cancelIntent: 'danger',
                onConfirm: async () => {
                    try {
                        // Check if transcription has been completed by Whisper
                        if (!isAwsTranscriber) {
                            for (const visitId of visitsIds) {
                                await postAudioComplete(visitId)
                            }
                        }
                    } catch (error) {
                        console.error('Error completing audio:', error)
                    } finally {
                        deleteVisits(visitsIds)
                            .then(() => {
                                toast.success(
                                    `${
                                        visitsCount > 1 ? 'Visits' : 'Visit'
                                    } deleted`
                                )
                                navVisits()
                            })
                            .catch((error) => console.error(error))
                    }
                },
            })
        },
        [
            visitsIds,
            visitsCount,
            visitsGroupTitle,
            isAwsTranscriber,
            deleteVisits,
            navVisits,
        ]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isDeleteVisitLoading)}
        </Styles>
    )
}
