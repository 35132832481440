import 'react-toastify/dist/ReactToastify.css'

import { Slide, ToastContainer } from 'react-toastify'

interface Props {
    limit ?: number
}

export const Toast = ({limit}:Props) => (
    <ToastContainer
        position="bottom-right"
        limit={limit || 3}
        hideProgressBar
        transition={Slide}
    />
)
