import axios, { AxiosResponse } from 'axios'

import { storage } from '../../lib/storage'
import { User } from '../models/User.model'

const baseUrl = process.env.REACT_APP_API

const signIn = (email: string, password: string): Promise<User> => {
    const url = `${baseUrl}/auth/signin`
    const payload = {
        email,
        password,
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then((response: AxiosResponse) => {
                const user = response.data.user
                const session = response.data.session
                storage.storeAccessToken(session)
                storage.clearEmrToken()
                resolve(user)
            })
            .catch((error) => reject(error.response.data))
    })
}

const signUp = (email: string, password: string, referral?: string): Promise<void> => {
    const url = `${baseUrl}/auth/signup`
    const payload: { email: string; password: string; state?: string } = {
        email,
        password
    }
    if (referral) payload.state = referral

    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const signUpCompletion = (username: string, code: string): Promise<void> => {
    const url = `${baseUrl}/auth/signup/complete`
    const payload = {
        username,
        code,
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const resendEmailVerificationCode = (email: string): Promise<void> => {
    const url = `${baseUrl}/auth/resend-verification`
    const payload = {
        email,
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const forgotPassword = (email: string): Promise<void> => {
    const url = `${baseUrl}/auth/forgot-password`
    const payload = {
        email,
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const resetPassword = (email: string, password: string, code: string): Promise<void> => {
    const url = `${baseUrl}/auth/reset-password`
    const payload = {
        email,
        password,
        code,
    }
    return new Promise((resolve, reject) => {
        axios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const refresh = (): Promise<void> => {
    const url = `${baseUrl}/auth/refresh`
    const refreshToken = storage.getRefreshToken()
    const username = storage.getUsername()
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: {
                    'x-fl-refresh': refreshToken,
                    'x-fl-username': username,
                },
            })
            .then((response: AxiosResponse) => {
                const session = response.data.session
                storage.storeAccessToken(session)
                storage.clearEmrToken()
                resolve()
            })
            .catch((error) => reject(error.response.data))
    })
}

export const authService = {
    signIn,
    signUp,
    signUpCompletion,
    resendEmailVerificationCode,
    forgotPassword,
    resetPassword,
    refresh,
}
