import { Dialog, Transition } from '@headlessui/react'
import { ClockIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'

import { PAUSE_RECORDING_TIME_LIMIT } from '../../constants'
import { FinishButton } from '../Visit/components/Record/components/FinishButton'
import { RecorderHookState } from '../Visit/components/Record/hooks/_useRecorder'
import { Button } from './Button'
import { TimeLabel } from './TimeLabel'

export interface TimeoverModalProps {
    isOpen?: boolean
    onClose?(): void
    duration: number
    recorder: RecorderHookState
    visitId: string
}

export const TimeoverModal = ({ isOpen, onClose, recorder, visitId }: TimeoverModalProps) => {
    const [countdown, setCountdown] = useState(PAUSE_RECORDING_TIME_LIMIT)

    useEffect(() => {
        if (countdown < 0) return

        const pauseRecording = () => {
            if (countdown === 0) {
                recorder.onPause()
            }
            setCountdown((prev) => prev - 1)
        }

        const intervalId = setInterval(pauseRecording, 1000)

        // Cleanup interval when component unmounts
        return () => clearInterval(intervalId)
    }, [recorder, setCountdown, countdown, onClose])

    const continueRecordingHandler = ()=>{
        if(countdown < 0 ) recorder.onRecord()
        if(onClose) onClose()
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 w-screen overflow-y-visible">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md md:max-w-lg sm:px-3 sm:py-4 md:p-4">
                                <div className="flex">
                                    <ClockIcon className="h-10 w-10 text-primary mr-2" />
                                    <div className="flex flex-col gap-1 w-full">
                                        <Dialog.Title as="h3" className="text-lg mt-2 font-semibold leading-6 text-gray-700 mb-2">
                                            Are you still there ?
                                        </Dialog.Title>
                                        <Dialog.Description>
                                            {countdown > 0 ? (
                                                <>
                                                    <p className="text-base text-gray-600">You have been recording for over an hour.</p>
                                                    <p className="text-base text-gray-600">
                                                        If no action is taken, Fluent will pause the recording in {' '}
                                                        <TimeLabel className="font-bold text-slate-600" seconds={countdown} />
                                                    </p>
                                                </>
                                            ) : (
                                                <p className="text-base text-gray-600">Your recording has been paused due to inactivity</p>
                                            )}
                                        </Dialog.Description>
                                        <div className="flex mt-4 justify-end gap-2">
                                            <Button onClick={continueRecordingHandler} label="Continue recording" intent="text-primary" />
                                            <FinishButton callback={onClose} visitId={visitId} type="standard" showConfirmation={false} />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
