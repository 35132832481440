import { utilsService } from '../utils.service'

export class Plan {
    id!: string
    name!: string
    description!: string
    price!: Price[]
    features!: string[]
    metadata!: PlanMetadata

    static sort = (plans: Plan[]): Plan[] => {
        return plans.sort((a, b) => {
            return (
                planSortKey[a.metadata.app_plan_id] -
                planSortKey[b.metadata.app_plan_id]
            )
        })
    }

    static isFreePlan = (plan: Plan): boolean => {
        return plan.metadata.app_plan_id === 'free'
    }

    static isStandardPlan = (plan: Plan): boolean => {
        return plan.metadata.app_plan_id === 'standard'
    }

    static isEnterprisePlan = (plan: Plan): boolean => {
        return plan.metadata.app_plan_id === 'enterprise'
    }

    static isUpgrading = (currentPlan: Plan, newPlan: Plan): boolean => {
        return (
            planSortKey[newPlan.metadata.app_plan_id] >
            planSortKey[currentPlan.metadata.app_plan_id]
        )
    }

    static getPrice = (plan: Plan): Price | undefined => {
        return plan.price?.length ? plan.price[0] : undefined
    }
}

export type PlanMetadata = {
    app_plan_id: AppPlanId
}

export type AppPlanId =
    | 'free'
    | 'starter'
    | 'standard'
    | 'enterprise'
    | 'emr_integration'

const planSortKey: Record<string, number> = {
    free: 0,
    starter: 1,
    standard: 2,
    enterprise: 3,
    emr_integration: 4,
}

export type Price = {
    id: string
    amount: number
    interval: PriceInterval
    currency: string
}

export type PriceInterval = 'day' | 'week' | 'month' | 'year'

export class Subscription {
    id!: string
    status!: string
    currentPeriodEnd!: Date
    cancelAt!: Date | null
    discount?: Discount
    plan!: any

    static deseralize(obj: any): Subscription {
        const model: Subscription = new Subscription()
        model.id = obj.id
        model.status = obj.status
        model.discount = obj.discount
            ? Discount.deseralize(obj.discount)
            : undefined
        model.currentPeriodEnd = new Date(obj.current_period_end * 1000)
        model.plan = obj.plan
        model.cancelAt = obj.cancel_at ? new Date(obj.cancel_at * 1000) : null
        return model
    }

    static isSubscribed = (
        subscription: Subscription | undefined | null
    ): boolean | undefined => {
        return subscription === undefined
            ? undefined
            : subscription !== undefined && subscription?.status === 'active'
    }

    static getPlanId = (
        subscription: Subscription | undefined | null
    ): string => {
        return subscription?.plan?.product
    }

    static getDiscountedPrice = (
        coupon: Coupon | undefined,
        price: number,
        currency: string
    ): string => {
        let amount = price

        // Discount price by coupon amount or percentage
        if (coupon?.valid) {
            if (coupon?.amountOff && coupon?.currency === currency) {
                amount = price - coupon.amountOff / 100
            } else if (coupon?.percentOff) {
                amount = price * (1 - coupon.percentOff / 100)
            }
        }

        return utilsService.formatCurrency(amount, currency)
    }
}

export class PaymentMethod {
    id!: string
    card!: PaymentMethodCard

    static deseralize(obj: any): PaymentMethod {
        const model: PaymentMethod = new PaymentMethod()
        model.id = obj.id
        model.card = PaymentMethodCard.deseralize(obj.card)
        return model
    }
}

class PaymentMethodCard {
    last4!: string
    brand!: string
    exp_month!: string
    exp_year!: string

    static deseralize(obj: any): PaymentMethodCard {
        const model: PaymentMethodCard = new PaymentMethodCard()
        model.last4 = obj.last4
        model.brand = obj.brand
        model.exp_month = obj.exp_month ?? ''
        model.exp_year = obj.exp_year ?? ''

        // Add padding to month
        model.exp_month = model.exp_month.toString().padStart(2, '0')

        return model
    }
}

class Discount {
    id!: string
    subscription!: string
    promotionCode!: string
    coupon?: Coupon

    static deseralize(obj: any): Discount {
        const model: Discount = new Discount()
        model.id = obj.id
        model.subscription = obj.subscription
        model.promotionCode = obj.promotion_code
        model.coupon = obj.coupon ? Coupon.deseralize(obj.coupon) : undefined
        return model
    }
}

class Coupon {
    id!: string
    valid!: boolean
    amountOff!: number | null
    percentOff!: number | null
    currency!: string

    static deseralize(obj: any): Coupon {
        const model: Coupon = new Coupon()
        model.id = obj.id
        model.valid = obj.valid
        model.amountOff = obj.amount_off
        model.percentOff = obj.percent_off
        model.currency = obj.currency
        return model
    }
}

export class PromotionCode {
    id!: string
    active!: boolean
    code!: string
    coupon!: Coupon

    static deseralize(obj: any): PromotionCode {
        const model: PromotionCode = new PromotionCode()
        model.id = obj.id
        model.active = obj.active
        model.code = obj.code
        model.coupon = Coupon.deseralize(obj.coupon)
        return model
    }

    static getAmountOff = (promotionCode: PromotionCode): string => {
        let amount = 0
        if (promotionCode.coupon?.valid) {
            if (promotionCode.coupon?.amountOff) {
                amount = promotionCode.coupon.amountOff / 100 || 0
            } else if (promotionCode.coupon?.percentOff) {
                return `${promotionCode.coupon.percentOff}%`
            }
        }
        return utilsService.formatCurrency(amount)
    }
}

export class Invoice {
    id!: string
    currency!: string
    total!: number
    status!: string
    url!: string
    createdAt!: Date

    static deseralize(obj: any): Invoice {
        const model: Invoice = new Invoice()
        model.id = obj.id
        model.currency = obj.currency
        model.total = obj.total / 100
        model.status = obj.status
        model.url = obj.hosted_invoice_url
        model.createdAt = new Date(obj.created * 1000)
        return model
    }
}

export class BillingUsage {
    id!: string
    owner!: string
    billingPlanId!: string
    subscriptionId!: string
    visitsCreated!: number
    maxVisits!: number
    customTemplatesCreated!: number
    currentPeriodStart!: Date
    currentPeriodEnd!: Date
    createdAt!: Date
    updatedAt!: Date

    static deseralize(obj: any): BillingUsage {
        const model: BillingUsage = new BillingUsage()
        model.id = obj._id
        model.owner = obj.owner
        model.billingPlanId = obj.billingPlanId
        model.subscriptionId = obj.subscriptionId
        model.visitsCreated = obj.visitsCreated
        model.maxVisits = obj.maxVisits
        model.customTemplatesCreated = obj.customTemplatesCreated
        model.currentPeriodStart = new Date(obj.currentPeriodStart)
        model.currentPeriodEnd = new Date(obj.currentPeriodEnd)
        model.createdAt = new Date(obj.createdAt)
        model.updatedAt = new Date(obj.updatedAt)
        return model
    }
}

export type SubscriptionCancellationFeedback =
    | 'customer_service'
    | 'low_quality'
    | 'missing_features'
    | 'other'
    | 'switched_service'
    | 'too_complex'
    | 'too_expensive'
    | 'unused'
