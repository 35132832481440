import { CheckIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'

import { useRoute } from '../../../../../hooks/useRoute'
import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import { postAudioComplete } from '../../../../../lib/Whisper'
import { OpenConfirmationModal, VisitFailedToFinish } from '../../../../../services/event-bus/events'
import { Visit } from '../../../../../services/models/Visit.model'
import { useDeveloperContext } from '../../../../Settings/Developer/hooks/useDeveloperContext'
import { Button } from '../../../../UI/Button'
import { IconButton } from '../../../../UI/IconButton'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'
import { ButtonLabel, ButtonWrapper } from '../styles'

interface Props {
    visitId: string
    type: 'standard' | 'icon'
    showConfirmation?: boolean
    callback?: () => void
}

export const FinishButton = ({ visitId, type = 'icon', callback, showConfirmation = true }: Props) => {
    const { navVisit, navVisitRecord } = useRoute()
    const { getVisit } = useVisitsContext().visits
    const { generateVisitNote } = useVisitNotesContext().visitNotes
    const { isAwsTranscriber } = useDeveloperContext().developer

    const visit = useMemo(() => getVisit(visitId), [getVisit, visitId])

    const templateId: string | undefined = useLocation().state?.templateId
    const customTemplateId: string | undefined = useLocation().state?.customTemplateId

    // Check if transcription is long enough
    const canFinish = useMemo((): boolean => (visit ? Visit.canFinishRecording(visit) : false), [visit])

    const finishRecordingHandler = useCallback(async () => {
        try {
            // Run callback before
            if (callback) callback()

            // Check if transcription has been completed by Whisper
            if (!isAwsTranscriber) {
                await postAudioComplete(visitId)
            }
        } catch (error) {
            console.error('Error completing audio:', error)
        } finally {
            try {
                await generateVisitNote('note', templateId, customTemplateId, undefined, () => {
                    toast.error('Error generating note. Please try again.')
                    VisitFailedToFinish.emit(visitId)
                    navVisitRecord(visitId)
                })
            } catch (error) {
                console.error('Error generating visit note:', error)
            } finally {
                navVisit(visitId, visit)
            }
        }
    }, [isAwsTranscriber, visitId, callback, templateId, customTemplateId, generateVisitNote, navVisit, navVisitRecord, visit])

    const onClickHandler = useCallback(
        (event: any) => {
            event.stopPropagation()

            if (!visit) {
                return
            }

            if (!canFinish) {
                toast.warning(Visit.getStateDescription('transcribing'))
                return
            }

            OpenConfirmationModal.emit({
                titleNode: 'Finish recording',
                iconNode: <CheckCircleIcon className="h-10 w-10 text-green mr-4" />,
                bodyNode: 'Are you sure you want to finish this recording?',
                confirmLabel: 'Finish',
                onConfirm: finishRecordingHandler,
            })
        },
        [visit, canFinish, finishRecordingHandler]
    )

    if (type === 'standard') {
        return <Button label="Finish recording" intent="danger" onClick={showConfirmation ? onClickHandler : finishRecordingHandler} />
    }

    return (
        <ButtonWrapper>
            <div onClick={onClickHandler}>
                <IconButton iconNode={<CheckIcon className="h-5 w-5 text-green" />} />
            </div>
            <ButtonLabel>Finish</ButtonLabel>
        </ButtonWrapper>
    )
}
