import { OpenPaymentMethodModal } from '../../../../services/event-bus/events'
import { PaymentMethod } from '../../../../services/models/Subscription.model'
import { Button } from '../../../UI/Button'

interface Props {
    paymentMethod: PaymentMethod
}

export const CurrentPaymentMethod = ({ paymentMethod }: Props) => {
    return (
        <div className="flex items-center justify-between gap-2 border rounded-md p-3">
            <div className="flex items-center gap-1">
                {paymentMethod.card?.brand && (
                    <div className="text-sm font-medium mr-2 bg-gray-100 rounded-md px-2 py-1">
                        {paymentMethod.card?.brand}
                    </div>
                )}
                <div>
                    <div className="font-medium">
                        Ending in ...{paymentMethod.card?.last4}
                    </div>
                    <div className="text-sm text-gray-500">
                        Expires on {paymentMethod.card?.exp_month}/
                        {paymentMethod.card?.exp_year}
                    </div>
                </div>
            </div>

            <Button
                intent="text"
                label="Update"
                onClick={() =>
                    OpenPaymentMethodModal.emit({
                        paymentMethod,
                    })
                }
            />
        </div>
    )
}
