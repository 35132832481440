import { useEffect, useState } from 'react'

import { referralServices } from '../../../services/http/referral.service'
import { DividerLine } from '../../UI/DividerLine'
import { SectionWrapper } from '../styles'
import InviteByEmailCard from './InviteByEmailCard'
import { ReferralLinkCard } from './ReferralLinkCard'
import { ReferralsHistory } from './ReferralsHistory'

export type Lead = {
    id: string
    email: string
    state: string
    created_at: string
}

type Promoter = {
    id: string | number
    email: string
    referral_link: string
    earnings_balance: {
        cash: number
        credits: number
    }
    leads: Lead[]
}

export const Referral = () => {
    const [promoter, setPromoter] = useState<Promoter | null>(null)

    useEffect(() => {
        (async function () {
            try {
                const data: Promoter = await referralServices.getPromoter()
                setPromoter(data)
            } catch (error) {
                setPromoter(null)
            }
        })()
    }, [])

    return (
        <>
            <SectionWrapper title="Get Fluent Discount by sharing with your friends">
                <p className="text-gray-600">Earn $25 discount for every new signed-up user</p>
                <div className="flex my-3 flex-wrap">
                    <ReferralLinkCard inviteLink={promoter?.referral_link} />
                    <InviteByEmailCard inviteLink={promoter?.referral_link} />
                </div>
            </SectionWrapper>

            <DividerLine className="mt-6 mb-10" />

            <SectionWrapper title="Total credit earned">
                <h2 className="text-[var(--primary)] text-xl font-semibold">CA${((promoter?.earnings_balance?.cash || 0) / 100).toFixed(2)}</h2>
                <ReferralsHistory referralData={promoter?.leads} />
            </SectionWrapper>
        </>
    )
}
