import { OpenPaymentMethodModal } from '../../../../services/event-bus/events'
import { useBillingContext } from '../../../Billing/hooks/useBillingContext'
import { Button } from '../../../UI/Button'
import { SectionWrapper } from '../../styles'
import { CurrentPaymentMethod } from './CurrentPaymentMethod'

export const PaymentMethod = () => {
    const { paymentMethod } = useBillingContext().paymentMethod

    return (
        <SectionWrapper title="Payment method">
            {paymentMethod ? (
                <CurrentPaymentMethod paymentMethod={paymentMethod} />
            ) : (
                <Button
                    intent="text"
                    label="Add card"
                    onClick={() => OpenPaymentMethodModal.emit({})}
                />
            )}
        </SectionWrapper>
    )
}
