import { MutableRefObject, useEffect, useState } from 'react'

import { TIMEOVER_CONDITION } from '../../../../../constants'
import { OpenTimeoverModel } from '../../../../../services/event-bus/events'
import { useLocalContext } from './useLocalContext'

interface Props {
    audioRef: MutableRefObject<HTMLAudioElement|null>
    visitId: string
}

export const useTimeoverCheck = ({ audioRef, visitId }: Props) => {
    const { timer, recorder } = useLocalContext()
    const [passedTime, setPassedTime] = useState(0)

    useEffect(() => {
        const seconds = timer.duration
        if(seconds > 0 && passedTime === 0) setPassedTime(seconds)

        if (TIMEOVER_CONDITION(seconds, passedTime)) {
            audioRef?.current?.play().catch((err: Error) => {
                console.error(err)
            })
            OpenTimeoverModel.emit({ duration: seconds, recorder, visitId })
        }
    }, [timer, recorder, audioRef, visitId, passedTime])

    return null
}