import classNames from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'

import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { Visit } from '../../services/models/Visit.model'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'

interface Props {
    className?: string
    visitId: string
    visitNoteId: string
    children: ReactNode
}

export const AddTimestamp = ({
    className,
    visitId,
    visitNoteId,
    children,
}: Props) => {
    const { getVisit } = useVisitsContext().visits
    const { getVisitNoteById, updateVisitNotePartial, updateVisitNoteContent } =
        useVisitNotesContext().visitNotes

    const visit = useMemo(() => getVisit(visitId), [getVisit, visitId])
    const visitNote = useMemo(
        () => getVisitNoteById(visitNoteId),
        [getVisitNoteById, visitNoteId]
    )

    const onClick = useCallback(() => {
        if (!visit || !visitNote) {
            return
        }

        const durationNote = Visit.getDurationNote(visit)
        const content = visitNote.content
            ? `${visitNote.content}\n${durationNote}`
            : durationNote

        updateVisitNoteContent(visitNoteId, content).then(() => {
            updateVisitNotePartial(visitId, visitNoteId, {
                content,
            })
        })
    }, [
        visit,
        visitNote,
        visitId,
        visitNoteId,
        updateVisitNoteContent,
        updateVisitNotePartial,
    ])

    if (!visit) {
        return null
    }

    return (
        <div className={classNames(className)} onClick={onClick}>
            {children}
        </div>
    )
}
