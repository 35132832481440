import { PauseIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import styled from 'styled-components'

interface Props {
    className?: string
    minimal?: boolean
    label: string
    stopPulse: boolean
}

const Styles = styled.div`
    .shape {
        width: 12px;
        min-width: 12px;
        height: 12px;
        min-height: 12px;
        border-radius: 100px;
        display: inline-block;
    }
`

export const PulsingLabel = ({ className, minimal, label, stopPulse }: Props) => {
    return (
        <Styles className={classNames('flex items-center', !stopPulse && 'animate-pulse', className)}>
            {!stopPulse ? <div className="shape bg-red mr-2" /> : <PauseIcon className="h-6 w-6 text-red mr-1" />}
            {!minimal && <div className="text-red uppercase font-semibold">{label}</div>}
        </Styles>
    )
}
