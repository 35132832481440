import { PauseIcon } from '@heroicons/react/20/solid'
import { MicrophoneIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'
import styled from 'styled-components'

import Colors from '../../../../../../colors'
import { useAppContext } from '../../../../../../hooks/useAppContext'
import {
    DictationTranscriber,
    DictationTranscriberEventData,
} from '../../../../../../lib/DictationTranscriber'
import { Loader } from '../../../../../UI/Loader'

interface Props {
    className?: string
    onPartialContent(partialContent: string): void
    onContent(content: string): void
    onRecording?(isRecording: boolean): void
}

const Styles = styled.div`
    position: relative;
    z-index: 40;

    &.active {
        button {
            border-color: ${Colors.RED};
        }
    }

    button {
        padding: 4px 8px;
        border-radius: 100px;
        border: 1px solid #e0e0e0;
    }
`

export const Dictation = forwardRef(
    (
        { className, onPartialContent, onContent, onRecording }: Props,
        ref: any
    ) => {
        const { preferredMicrophoneDeviceId } = useAppContext().appSettings
        const [, setTranscriber] = useState<DictationTranscriber | null>(null)
        const [isRecording, setIsRecording] = useState<boolean>(false)
        const [isLoading, setIsLoading] = useState<boolean>(false)

        const onRecord = useCallback(() => {
            const _transcriber = new DictationTranscriber(
                preferredMicrophoneDeviceId ?? undefined
            )

            const subscription = _transcriber
                ?.getObservable()
                .subscribe((eventData: DictationTranscriberEventData) => {
                    if ('isRecording' in eventData) {
                        setIsRecording(eventData.isRecording)
                        onRecording?.(eventData.isRecording)
                    } else if ('isLoading' in eventData) {
                        setIsLoading(eventData.isLoading)
                    } else if ('partialContent' in eventData) {
                        onPartialContent(eventData.partialContent)
                    } else if ('content' in eventData) {
                        onContent(eventData.content)
                    }
                })

            setTranscriber(_transcriber)
            _transcriber.start()

            return () => subscription?.unsubscribe()
        }, [
            preferredMicrophoneDeviceId,
            onPartialContent,
            onContent,
            onRecording,
        ])

        const onStop = useCallback(() => {
            setTranscriber((prev) => {
                prev?.stop()
                return null
            })
        }, [])

        // Pass references to parent component
        useImperativeHandle(ref, () => ({
            onStop() {
                return onStop()
            },
        }))

        const node = useMemo(() => {
            if (isLoading) {
                return <Loader />
            }
            return isRecording ? (
                <PauseIcon className="h-5 w-5 text-red animate-[pulse_1000ms_ease-in-out_infinite]" />
            ) : (
                <MicrophoneIcon className="h-5 w-5 text-gray-500" />
            )
        }, [isLoading, isRecording])

        return (
            <Styles
                ref={ref}
                className={classNames(className, {
                    active: isRecording,
                })}
            >
                <button onClick={isRecording ? onStop : onRecord}>
                    {node}
                </button>
            </Styles>
        )
    }
)

Dictation.displayName = 'Dictation'
