import { createSlice } from '@reduxjs/toolkit'

import { storage } from '../../lib/storage'

interface Action {
    type: string
    payload: string
}

interface InitialState {
    preferredMicrophone: string | null
}

const deviceId = storage.getMicrophoneDeviceId()

const initialState: InitialState = {
    preferredMicrophone: deviceId
}

const appSlice = createSlice({
    initialState,
    name: 'app',
    reducers: {
        setPreferredMicrophone(state, action: Action) {
            state.preferredMicrophone = action.payload
        },
        clearPreferredMicrophone(state) {
            state.preferredMicrophone = null
        }
    }
})

export const { setPreferredMicrophone, clearPreferredMicrophone } = appSlice.actions
export default appSlice.reducer
