import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Billing } from '../components/Settings/Billing/Billing'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!)

export const BillingPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <Billing />
        </Elements>
    )
}
