import classNames from 'classnames'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { Language } from '../../../../constants'
import { useAppContext } from '../../../../hooks/useAppContext'
import { LanguagesListBox } from '../../../UI/LanguagesListBox'

interface Props {
    className?: string
}

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;

    ul {
        width: 280px;
    }
`

const SelectDefaultNoteLanguage = ({ className }: Props) => {
    const { preferences, isPreferenceLoading, updatePreferences } =
        useAppContext().preferences

    const onChange = useCallback(
        (language: Language) => {
            if (!preferences?._id) {
                return
            }
            updatePreferences(preferences._id, {
                defaultNoteLanguage: language,
            }).catch((error) => {
                console.error(error)
                toast.error('Unable to update preferences')
            })
        },
        [preferences?._id, updatePreferences]
    )

    if (isPreferenceLoading) {
        return null
    }

    return (
        <Styles className={classNames(className)}>
            <div className="block text-sm font-medium mb-1 leading-6 text-gray-900">
                Default note language
            </div>
            <LanguagesListBox
                value={preferences?.defaultNoteLanguage}
                onChange={onChange}
            />
        </Styles>
    )
}

export default SelectDefaultNoteLanguage
