import { Switch } from '@headlessui/react'

interface Props {
    value: boolean
    onChange(value: boolean): void
}

export const Toggle = ({ value, onChange }: Props) => {
    return (
        <Switch
            checked={value}
            onChange={onChange}
            className={`${
                value ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
            <span
                className={`${
                    value ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
        </Switch>
    )
}
