import { Menu } from '@headlessui/react'
import { ArrowUpCircleIcon, SparklesIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { produce } from 'immer'
import { useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import {
    FieldError,
    initialTextField,
    TextField,
} from '../../../../../lib/field'
import { Button } from '../../../../UI/Button'
import { Input } from '../../../../UI/Input'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'
import { Dictation } from './Dictation/Dictation'
import { SuggestedUserCommandsMenu } from './SuggestedUserCommandsMenu'

const Styles = styled.div`
    .input-wrapper {
        > div:first-child {
            pointer-events: initial;
        }

        input {
            padding: 16px 100px 16px 60px;
        }

        & > div {
            padding-right: 10px;
        }
    }

    button {
        min-width: unset;
        padding: 6px 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
            padding: 0;
            position: inherit;
        }
    }
`

export const UserCommand = () => {
    const { id: visitId } = useParams()
    const { getVisit } = useVisitsContext().visits
    const { generateVisitNote, isProcessing } =
        useVisitNotesContext().visitNotes
    const { form, setCommand, updateCommand, validateForm, resetForm } =
        useForm()
    const [partialCommand, setPartialCommand] = useState('')
    const [isRecording, setIsRecording] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const dictationRef = useRef<any>(null)

    const visit = useMemo(
        () => (visitId ? getVisit(visitId) : undefined),
        [visitId, getVisit]
    )

    const onSubmit = useCallback(
        (event?: any) => {
            event?.stopPropagation()

            if (isProcessing || !validateForm()) {
                return
            }
            generateVisitNote(
                'user-command',
                visit?.templateId,
                visit?.customTemplateId,
                form.command.value as string
            ).catch((error) => {
                console.error('Error generating visit note:', error)
            })

            // Reset form
            resetForm()
            setPartialCommand('')

            // Stop command line dictation
            dictationRef?.current?.onStop()
        },
        [
            visit?.templateId,
            visit?.customTemplateId,
            isProcessing,
            form.command.value,
            resetForm,
            validateForm,
            generateVisitNote,
        ]
    )

    const setCursorToEnd = useCallback(() => {
        if (!inputRef?.current) {
            return
        }
        const length = inputRef.current.value.length
        inputRef.current.setSelectionRange(length, length)
        inputRef.current.scrollLeft = inputRef.current.scrollWidth
    }, [])

    if (!visit) {
        return null
    }

    return (
        <Styles className="relative">
            <Input
                ref={inputRef}
                inputClassName={classNames(isRecording && 'ring-primary')}
                name="user-command"
                placeholder="Ask me anything, or enter your command..."
                initialValue={
                    ((form.command.value as string) || '') + partialCommand
                }
                error={form.command.error}
                onChange={setCommand}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onSubmit()
                    }
                }}
                iconNode={
                    <Dictation
                        ref={dictationRef}
                        onPartialContent={(content) => {
                            setPartialCommand(content)
                            setTimeout(setCursorToEnd, 0)
                        }}
                        onContent={(content) => {
                            updateCommand(content)
                            setPartialCommand('')
                            setTimeout(setCursorToEnd, 0)
                        }}
                        onRecording={setIsRecording}
                    />
                }
                rightNode={
                    <div className="flex items-center">
                        <SuggestedUserCommandsMenu
                            className="mr-5"
                            visitId={visit._id}
                            visitState={visit.state}
                            templateId={visit.templateId}
                        >
                            <Menu.Button
                                className="absolute right-14 bottom-2.5 flex rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 z-40"
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    dictationRef?.current?.onStop()
                                }}
                            >
                                <SparklesIcon className="h-6 w-6 text-gray-500" />
                            </Menu.Button>
                        </SuggestedUserCommandsMenu>
                        <Button
                            label=""
                            disabled={isProcessing}
                            onClick={onSubmit}
                            leftIconNode={
                                <ArrowUpCircleIcon className="h-6 w-6" />
                            }
                        />
                    </div>
                }
            />
        </Styles>
    )
}

class FormFields {
    command!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    command: initialTextField,
}

enum ACTION {
    SET_COMMAND,
    UPDATE_COMMAND,
    UPDATE_ERROR,
    RESET,
}

type Action =
    | {
          type: ACTION.SET_COMMAND
          command: string
      }
    | {
          type: ACTION.UPDATE_COMMAND
          command: string
      }
    | {
          type: ACTION.UPDATE_ERROR
          error: FieldError
      }
    | {
          type: ACTION.RESET
      }

const reducer = (form: FormFields, action: Action): FormFields => {
    return produce(form, (draft) => {
        switch (action.type) {
            case ACTION.SET_COMMAND:
                draft.command.value = action.command || ''
                draft.command.error = null
                break

            case ACTION.UPDATE_COMMAND:
                draft.command.value =
                    (draft.command.value ?? '') + action.command || ''
                draft.command.error = null
                break

            case ACTION.UPDATE_ERROR:
                draft.command.error = action.error
                break

            case ACTION.RESET:
                draft.command.value = ''
                draft.command.error = null
                break
        }
    })
}

const useForm = () => {
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const setCommand = useCallback((command: string) => {
        dispatch({
            type: ACTION.SET_COMMAND,
            command,
        })
    }, [])

    const updateCommand = useCallback((command: string) => {
        dispatch({
            type: ACTION.UPDATE_COMMAND,
            command,
        })
    }, [])

    const updateError = useCallback((error: FieldError) => {
        dispatch({
            type: ACTION.UPDATE_ERROR,
            error,
        })
    }, [])

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'command': {
                    if (!value) {
                        updateError('Required')
                        return false
                    }
                    return true
                }
                default: {
                    return true
                }
            }
        },
        [form, updateError]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('command'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const resetForm = useCallback(() => {
        dispatch({
            type: ACTION.RESET,
        })
    }, [])

    return {
        form,
        setCommand,
        updateCommand,
        validateField,
        validateForm,
        resetForm,
    }
}
