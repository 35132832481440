import { useCallback, useEffect, useState } from 'react'

import { User } from '../../../../services/models/User.model'

enum TRANSCRIBER {
    AWS_TRANSCRIBER,
    WHISPER,
    AZURE,
}

export interface DeveloperHookState {
    isAwsTranscriber: boolean
    isWhisperTranscriber: boolean
    isAzureTranscriber: boolean
    downloadAudioFile: boolean
    logAudioChunks: boolean
    setAwsTranscriber(): void
    setWhisperTranscriber(): void
    setAzureTranscriber(): void
    setDownloadAudioFile(downloadAudioFile: boolean): void
    setLogAudioChunks(logAudioChunks: boolean): void
}

export const useDeveloper = (user: User | null): DeveloperHookState => {
    const [transcriberType, setTranscriberType] = useState<TRANSCRIBER>(
        TRANSCRIBER.AZURE
    )
    useEffect(
        () =>
            setTranscriberType(
                user?.isWhisperTester ? TRANSCRIBER.WHISPER : TRANSCRIBER.AZURE
            ),
        [user]
    )

    const [downloadAudioFile, setDownloadAudioFile] = useState<boolean>(false)
    const [logAudioChunks, setLogAudioChunks] = useState<boolean>(false)

    const isAwsTranscriber = transcriberType === TRANSCRIBER.AWS_TRANSCRIBER

    const isWhisperTranscriber = transcriberType === TRANSCRIBER.WHISPER

    const isAzureTranscriber = transcriberType === TRANSCRIBER.AZURE

    const setAwsTranscriber = useCallback(
        () => setTranscriberType(TRANSCRIBER.AWS_TRANSCRIBER),
        []
    )

    const setWhisperTranscriber = useCallback(
        () => setTranscriberType(TRANSCRIBER.WHISPER),
        []
    )

    const setAzureTranscriber = useCallback(
        () => setTranscriberType(TRANSCRIBER.AZURE),
        []
    )

    const setDownloadAudioFileHandler = useCallback(
        (_downloadAudioFile: boolean) =>
            setDownloadAudioFile(_downloadAudioFile),
        []
    )

    const logAudioChunksHandler = useCallback(
        (_logAudioChunks: boolean) => setLogAudioChunks(_logAudioChunks),
        []
    )

    return {
        isAwsTranscriber,
        isWhisperTranscriber,
        isAzureTranscriber,
        downloadAudioFile,
        logAudioChunks,
        setAwsTranscriber,
        setWhisperTranscriber,
        setAzureTranscriber,
        setDownloadAudioFile: setDownloadAudioFileHandler,
        setLogAudioChunks: logAudioChunksHandler,
    }
}
