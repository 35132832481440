import DetectRTC from 'detectrtc'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { storage } from '../lib/storage'
import { clearPreferredMicrophone, setPreferredMicrophone } from '../redux/reducers/app-slice'

export interface AppSettingsHookState {
    preferredMicrophoneDeviceId: string | null
    setPreferredMicrophoneDeviceId(deviceId: string): void
}

export const useAppSettings = () => {
    const [preferredMicrophoneDeviceId, setPreferredMicrophoneDeviceId] = useState<string | null>(null)
    const dispatch = useDispatch()

    const onSetPreferredMicrophoneDeviceId = useCallback(
        (deviceId: string) => {
            setPreferredMicrophoneDeviceId(deviceId)
            storage.storeMicrophoneDeviceId(deviceId)
            dispatch(setPreferredMicrophone(deviceId))
        },
        [dispatch]
    )

    useEffect(() => {
        const deviceId = storage.getMicrophoneDeviceId()
        if (!deviceId) {
            return
        }
        // Check if deviceId exists
        DetectRTC.load(() => {
            const microphones = DetectRTC.audioInputDevices
            const hasMicrophone = microphones.some((i) => i.deviceId === deviceId)
            if (hasMicrophone) {
                setPreferredMicrophoneDeviceId(deviceId)
                dispatch(setPreferredMicrophone(deviceId))
            } else if (microphones?.length === 1) {
                setPreferredMicrophoneDeviceId(microphones[0].deviceId)
                dispatch(setPreferredMicrophone(microphones[0].deviceId))
                storage.storeMicrophoneDeviceId(microphones[0].deviceId)
            } else {
                setPreferredMicrophoneDeviceId(null)
                storage.clearMicrophoneDeviceId()
                dispatch(clearPreferredMicrophone())
            }
        })
    }, [dispatch])

    return {
        preferredMicrophoneDeviceId,
        setPreferredMicrophoneDeviceId: onSetPreferredMicrophoneDeviceId
    }
}
