import { useCallback, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useRoute } from '../../../../hooks/useRoute'
import {
    AppPlanId,
    Plan,
    PromotionCode,
    Subscription,
} from '../../../../services/models/Subscription.model'
import { utilsService } from '../../../../services/utils.service'
import { useBillingContext } from '../../../Billing/hooks/useBillingContext'
import { Button } from '../../../UI/Button'
import { DividerLine } from '../../../UI/DividerLine'
import { Loader } from '../../../UI/Loader'
import { Logo } from '../../../UI/Logo'
import { PaymentMethodForm } from '../PaymentMethodForm'
import { PromotionCodeForm } from '../PromotionCodeForm'

const Styles = styled.div``

export const BillingCheckout = () => {
    const { appPlanId } = useParams()
    const { navBilling } = useRoute()
    const { getPlanByAppPlanId, isLoading: priceIsLoading } =
        useBillingContext().plans
    const { attachPaymentMethod, isLoading: paymentMethodIsLoading } =
        useBillingContext().paymentMethod
    const {
        subscription,
        isLoading: subscriptionIsLoading,
        createSubscription,
    } = useBillingContext().subscription
    const { getInvoices, isLoading: invoicesAreLoading } =
        useBillingContext().invoices
    const [promotionCode, setPromotionCode] = useState<PromotionCode | null>(
        null
    )
    const paymentFormRef = useRef<any>(null)

    const isLoading =
        paymentMethodIsLoading ||
        subscriptionIsLoading ||
        priceIsLoading ||
        invoicesAreLoading

    const plan = useMemo(
        () =>
            appPlanId ? getPlanByAppPlanId(appPlanId as AppPlanId) : undefined,
        [appPlanId, getPlanByAppPlanId]
    )

    const price = useMemo(
        () => (plan ? Plan.getPrice(plan) : undefined),
        [plan]
    )

    const totalPriceAmount = useMemo(() => {
        return Subscription.getDiscountedPrice(
            subscription?.discount?.coupon || promotionCode?.coupon,
            price?.amount || 0,
            price?.currency || 'cad'
        )
    }, [
        price?.amount,
        price?.currency,
        subscription?.discount?.coupon,
        promotionCode?.coupon,
    ])

    const onSubscribe = useCallback(async () => {
        if (!subscription?.id || !price?.id) {
            return
        }

        try {
            // Get payment method from form
            const paymentMethodId = await paymentFormRef.current.onSubmit()

            // Attach payment method to current user's stripe customer
            await attachPaymentMethod(paymentMethodId)

            // Create subscription with promotion code if available
            await createSubscription(
                subscription.id,
                price.id,
                promotionCode?.id
            )

            // Update invoices and navigate to billing page
            await getInvoices()
            navBilling()

            toast.success('You have successfully subscribed to Fluent!')
        } catch (error: any) {
            console.error(error)

            // Handle specific error cases
            const isCardDeclined = [
                'card_declined',
                'expired_card',
                'incorrect_cvc',
                'processing_error',
                'incorrect_number',
            ].includes(error?.response?.data?.message?.code)

            if (isCardDeclined) {
                toast.error('Card declined. Please check your card details.')
            } else if (error?.message) {
                toast.error(error.message)
            } else {
                toast.error('Unable to subscribe. Please try again later.')
            }
        }
    }, [
        subscription?.id,
        price?.id,
        promotionCode?.id,
        attachPaymentMethod,
        createSubscription,
        getInvoices,
        navBilling,
    ])

    if (isLoading) {
        return (
            <Styles>
                <Loader />
            </Styles>
        )
    }

    if (!plan || !price) {
        return (
            <Styles>
                <div>An error has occured. Please go back and try again</div>
            </Styles>
        )
    }

    return (
        <Styles className="block sm:flex items-start">
            <div className="w-full sm:w-1/2 border p-4 rounded-md">
                <PaymentMethodForm ref={paymentFormRef} />
                <PromotionCodeForm
                    className="mt-3"
                    promotionCode={promotionCode}
                    onChange={setPromotionCode}
                />
                {price && (
                    <Button
                        className="mt-4 w-full"
                        label={`Pay ${totalPriceAmount}`}
                        onClick={onSubscribe}
                        isLoading={isLoading}
                    />
                )}
            </div>
            <div className="w-full sm:w-1/2 border p-4 rounded-md ml-0 sm:ml-3 mt-8 sm:mt-0 bg-gray-50">
                <div className="font-medium">Summary</div>
                <DividerLine className="my-4" />
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Logo className="mr-2" type="logo" />
                        <div>
                            <div className="font-medium text-lg">
                                {plan.name}
                            </div>
                            <div className="text-gray-500">
                                Billed per {price?.interval}
                            </div>
                        </div>
                    </div>
                    <div>
                        {utilsService.formatCurrency(price.amount)}/
                        {price.interval}
                    </div>
                </div>
                <DividerLine className="my-4" />
                <div className="flex justify-between">
                    <div className="text-gray-500">Subtotal</div>
                    <div>{utilsService.formatCurrency(price.amount)}</div>
                </div>
                {promotionCode && (
                    <>
                        <DividerLine className="my-4" />
                        <div className="flex justify-between">
                            <div className="text-gray-500">Promo code</div>
                            <div>
                                -{PromotionCode.getAmountOff(promotionCode)}
                            </div>
                        </div>
                    </>
                )}
                <DividerLine className="my-4" />
                <div className="flex justify-between">
                    <div className="text-gray-500">Total</div>
                    <div className="font-semibold text-lg">
                        {totalPriceAmount}
                    </div>
                </div>
            </div>
        </Styles>
    )
}
