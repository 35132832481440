import { useEffect, useState } from 'react'

import { PaymentMethodModal, PaymentMethodModalProps } from './components/Settings/Billing/PaymentMethod/PaymentMethodModal'
import { AutofillEFormsModal, AutofillEFormsModalProps } from './components/UI/AutofillEFormsModal'
import { CancelSubscriptionFeedbackModal, CancelSubscriptionFeedbackModalProps } from './components/UI/CancelSubscriptionFeedbackModal'
import { ConfirmationModal, ConfirmationModalProps } from './components/UI/ConfirmationModal'
import { MicrophonesModal, MicrophonesModalProps } from './components/UI/MicrophonesModal'
import { TimeoverModal, TimeoverModalProps } from './components/UI/TimeoverModal'
import { Toast } from './components/UI/Toast'
import { eventBus, EventBusData } from './services/event-bus/eventBus.service'
import {
    OpenAutofillEFormsModal,
    OpenCancelSubscriptionFeedbackModal,
    OpenConfirmationModal,
    OpenMicrophonesModal,
    OpenPaymentMethodModal,
    OpenTimeoverModel,
} from './services/event-bus/events'

export const Portal = () => {
    const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps | null>(null)
    const [microphonesModalProps, setMicrophonesModalProps] = useState<MicrophonesModalProps | null>(null)
    const [timeoverModalProps, setTimeoverModalProps] = useState<TimeoverModalProps | null>(null)
    const [autofillEFormsModalProps, setAutofillEFormsModalProps] = useState<AutofillEFormsModalProps | null>(null)
    const [paymentMethodModalProps, setPaymentMethodModalProps] = useState<PaymentMethodModalProps | null>(null)
    const [cancelSubscriptionFeedbackModalProps, setCancelSubscriptionFeedbackModalProps] = useState<CancelSubscriptionFeedbackModalProps | null>(null)

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event: EventBusData) => {
            switch (event.action) {
                case OpenConfirmationModal.action: {
                    const payload = event.payload as ConfirmationModalProps
                    setConfirmationModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setConfirmationModalProps(null)
                        },
                    })
                    break
                }

                case OpenMicrophonesModal.action: {
                    const payload = event.payload as MicrophonesModalProps
                    setMicrophonesModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setMicrophonesModalProps(null)
                        },
                    })
                    break
                }

                case OpenTimeoverModel.action: {
                    const payload = event.payload as TimeoverModalProps
                    setTimeoverModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setTimeoverModalProps(null)
                        },
                    })
                    break
                }

                case OpenAutofillEFormsModal.action: {
                    const payload = event.payload as AutofillEFormsModalProps
                    setAutofillEFormsModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setAutofillEFormsModalProps(null)
                        },
                    })
                    break
                }

                case OpenPaymentMethodModal.action: {
                    const payload = event.payload as PaymentMethodModalProps
                    setPaymentMethodModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setPaymentMethodModalProps(null)
                        },
                    })
                    break
                }

                case OpenCancelSubscriptionFeedbackModal.action: {
                    const payload = event.payload as CancelSubscriptionFeedbackModalProps
                    setCancelSubscriptionFeedbackModalProps({
                        ...payload,
                        isOpen: true,
                        onClose: () => {
                            setCancelSubscriptionFeedbackModalProps(null)
                        },
                    })
                    break
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <>
            <Toast />

            {confirmationModalProps && <ConfirmationModal {...confirmationModalProps} />}
            {microphonesModalProps && <MicrophonesModal {...microphonesModalProps} />}
            {timeoverModalProps && <TimeoverModal {...timeoverModalProps} />}
            {autofillEFormsModalProps && <AutofillEFormsModal {...autofillEFormsModalProps} />}
            {paymentMethodModalProps && <PaymentMethodModal {...paymentMethodModalProps} />}
            {cancelSubscriptionFeedbackModalProps && <CancelSubscriptionFeedbackModal {...cancelSubscriptionFeedbackModalProps} />}
        </>
    )
}
