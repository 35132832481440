import { InformationCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useRoute } from '../../hooks/useRoute'
import { useBillingContext } from '../Billing/hooks/useBillingContext'
import { Button } from './Button'

interface Props {
    className?: string
}

const Styles = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        min-height: unset;
        padding: 6px 8px;
    }
`

export const SubscriptionStatusBanner = ({ className }: Props) => {
    const { navBilling } = useRoute()
    const {
        isLoading,
        subscription,
        isSubscribed,
        isSubscriptionPending,
        isSubscriptionPastDue,
    } = useBillingContext().subscription

    const message = useMemo(() => {
        if (isSubscriptionPending) {
            return 'Subscription pending payment'
        } else if (isSubscriptionPastDue) {
            return 'Subscription past due'
        }
        return subscription?.status
    }, [isSubscriptionPending, isSubscriptionPastDue, subscription?.status])

    if (isLoading || !subscription || isSubscribed) {
        return null
    }

    return (
        <Styles
            className={classNames(
                'bg-orange-50 text-orange border-b',
                className
            )}
        >
            <InformationCircleIcon className="h-6 w-6 mr-2" />
            <div className="font-semibold mr-1">{message}</div>
            <Button
                intent="text-secondary-fade"
                className="ml-4"
                label="View Billing"
                onClick={navBilling}
            />
        </Styles>
    )
}
