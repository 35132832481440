import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useRoute } from '../../../hooks/useRoute'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { Button } from '../../UI/Button'
import { DividerLine } from '../../UI/DividerLine'
import { GoogleAuthButton } from '../../UI/GoogleAuthButton'
import { Input } from '../../UI/Input'
import { useAuthContext } from '../hooks/useAuthContext'
import { useForm } from './useForm'

const cognitoAuthUrl = process.env.REACT_APP_COGNITO_AUTH_URL

export const Form = () => {
    const email = useLocation().state?.email
    const { forgotPasswordRoute, navMain, navBillingCheckout } = useRoute()
    const { setUser } = useAuthContext().user
    const { form, isLoading, updateField, validateField, signIn } =
        useForm(email)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const onSubmit = useCallback(
        () =>
            signIn(() => {
                return {
                    navMain,
                    navBillingCheckout,
                    setUser,
                }
            }),
        [signIn, navMain, navBillingCheckout, setUser]
    )
    useSubmitOnEnter(onSubmit)

    return (
        <>
            <GoogleAuthButton className="w-full" cognitoAuthUrl={cognitoAuthUrl} />
            <DividerLine className="my-5">OR</DividerLine>

            <Input
                name="email"
                initialValue={form.email.value as string}
                onChange={(value: string) => updateField('email', value)}
                onBlur={() => validateField('email')}
                labelNode="Email"
                error={form.email.error}
            />
            <Input
                className="mt-5"
                name="password"
                type={showPassword ? 'text' : 'password'}
                initialValue={form.password.value as string}
                onChange={(value: string) => updateField('password', value)}
                onBlur={() => validateField('password')}
                labelNode="Password"
                rightNode={
                    showPassword ? (
                        <EyeIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(false)}
                        />
                    ) : (
                        <EyeSlashIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(true)}
                        />
                    )
                }
                error={form.password.error}
            />
            <Link
                className="text-sm font-medium leading-6 text-gray-500 hover:text-gray-600 inline-block mt-1"
                to={forgotPasswordRoute}
            >
                Forgot password?
            </Link>

            <Button
                className="w-full mt-5 pl-2"
                label="Continue"
                onClick={onSubmit}
                isLoading={isLoading}
            />
        </>
    )
}
