import { useCallback, useEffect, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { paymentsService } from '../../../services/http/payments.service'
import { PaymentMethod } from '../../../services/models/Subscription.model'

export type PaymentMethodHookState = {
    paymentMethod: PaymentMethod | null
    isLoading: boolean
    getPaymentMethod(): Promise<void>
    attachPaymentMethod(paymentMethodId: string): Promise<void>
    deattachPaymentMethod(paymentMethodId: string): Promise<void>
}

export const usePaymentMethod = (
    stripeCustomerId: string | undefined
): PaymentMethodHookState => {
    const {
        isLoading,
        getPaymentMethodReq,
        attachPaymentMethodReq,
        deattachPaymentMethodReq,
    } = useHttpReq()
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
        null
    )
    const hasFetchedRef = useRef<boolean>(false)

    const attachPaymentMethod = useCallback(
        async (paymentMethodId: string) => {
            if (!stripeCustomerId) {
                return Promise.reject('stripeCustomerId is missing')
            }

            return attachPaymentMethodReq(
                stripeCustomerId,
                paymentMethodId
            ).then(() => {
                getPaymentMethodReq(stripeCustomerId)
                    .then(setPaymentMethod)
                    .catch((error) => console.error(error))
            })
        },
        [stripeCustomerId, attachPaymentMethodReq, getPaymentMethodReq]
    )

    const deattachPaymentMethod = useCallback(
        async (paymentMethodId: string) => {
            return deattachPaymentMethodReq(paymentMethodId)
                .then(() => {
                    setPaymentMethod(null)
                })
                .catch((error) => console.error(error))
        },
        [deattachPaymentMethodReq]
    )

    const getPaymentMethod = useCallback(async () => {
        if (!stripeCustomerId) {
            return Promise.reject('stripeCustomerId is missing')
        }
        return getPaymentMethodReq(stripeCustomerId)
            .then(setPaymentMethod)
            .catch((error) => console.error(error))
    }, [getPaymentMethodReq, stripeCustomerId])

    useEffect(() => {
        if (hasFetchedRef.current || !stripeCustomerId) {
            return
        }
        getPaymentMethod()
        hasFetchedRef.current = true
    }, [getPaymentMethod, stripeCustomerId])

    return {
        paymentMethod,
        isLoading,
        getPaymentMethod,
        attachPaymentMethod,
        deattachPaymentMethod,
    }
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getPaymentMethodReq = useCallback(
        (stripeCustomerId: string): Promise<PaymentMethod | null> =>
            sendRequest(
                paymentsService.getPaymentMethod.bind({}, stripeCustomerId)
            ),
        [sendRequest]
    )
    const attachPaymentMethodReq = useCallback(
        (stripeCustomerId: string, paymentMethodId: string): Promise<void> =>
            sendRequest(
                paymentsService.attachPaymentMethod.bind(
                    {},
                    stripeCustomerId,
                    paymentMethodId
                )
            ),
        [sendRequest]
    )

    const deattachPaymentMethodReq = useCallback(
        (paymentMethodId: string): Promise<void> =>
            sendRequest(
                paymentsService.deattachPaymentMethod.bind({}, paymentMethodId)
            ),
        [sendRequest]
    )

    return {
        isLoading,
        getPaymentMethodReq,
        attachPaymentMethodReq,
        deattachPaymentMethodReq,
    }
}
