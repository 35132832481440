import classNames from 'classnames'
import styled from 'styled-components'

import { Language } from '../../constants'

interface Props {
    className?: string
    message: string
    translated?: string
    languageSource?: Language
    languageTarget?: Language
}

const Styles = styled.div`
    box-sizing: content-box;
    position: relative;

    &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right-color: inherit;
        border-top-color: inherit;
        left: -7px;
        top: 6px;
    }

    .rtl {
        direction: rtl;
    }
`

export const SpeechBubble = ({
    className,
    message,
    translated,
    languageSource,
    languageTarget,
}: Props) => {
    return (
        <Styles className={classNames('rounded-md inline-block', className)}>
            <div
                className={classNames('px-2 py-1', {
                    rtl: languageSource === 'ar-SY',
                })}
            >
                {message}
            </div>
            {translated && (
                <div
                    className={classNames(
                        'px-2 py-1 border-t border-gray-300 text-primary',
                        {
                            rtl: languageTarget === 'ar-SY',
                        }
                    )}
                >
                    {translated}
                </div>
            )}
        </Styles>
    )
}
