import { useMemo } from 'react'

import { User } from '../../../services/models/User.model'
import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { DividerLine } from '../../UI/DividerLine'
import { GoogleUser } from '../../UI/GoogleUser'
import { CreatePassword } from './CreatePassword/CreatePassword'
import SelectDefaultNoteLanguage from './SelectDefaultNoteLanguage/SelectDefaultNoteLanguage'
import { SelectMicrophone } from './SelectMicrophone/SelectMicrophone'
import { UpdateEmail } from './UpdateEmail/UpdateEmail'
import { UpdatePassword } from './UpdatePassword/UpdatePassword'

export const Account = () => {
    const { user } = useAuthContext().user

    const isGoogleUser = useMemo(() => (user ? User.isGoogleUser(user) : false), [user])

    const isEmrUser = useMemo(() => (user ? User.isEmrUser(user) : false), [user])

    const canCreatePassword = useMemo(() => {
        if (!user) {
            return false
        }
        const hasUsername = !!user.username
        return !hasUsername && isEmrUser
    }, [user, isEmrUser])

    const canUpdateEmailOrPassword = useMemo(() => {
        if (!user) {
            return false
        }
        const hasUsername = !!user.username
        return hasUsername
    }, [user])

    const settingsNode = (
        <>
            <SelectMicrophone />
            <SelectDefaultNoteLanguage className="mt-8" />
        </>
    )

    if (isGoogleUser) {
        return (
            <>
                {settingsNode}
                <DividerLine className="my-8" />
                <GoogleUser user={user!} />
            </>
        )
    }

    return (
        <>
            {settingsNode}
            {canUpdateEmailOrPassword && <UpdateEmail />}
            {canCreatePassword && <CreatePassword />}
            {canUpdateEmailOrPassword && <UpdatePassword />}
        </>
    )
}
