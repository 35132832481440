import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk'
import { toast } from 'react-toastify'
import { Subject } from 'rxjs'

import { DEFAULT_LANGUAGE } from '../constants'
import { servicesService } from '../services/http/services.service'

const MAX_RETRIES = 3

export class DictationTranscriber {
    deviceId?: string
    tokenObj: {
        token: string
        region: string
    } | null = null
    recognizer: speechsdk.SpeechRecognizer | null = null
    subject: Subject<DictationTranscriberEventData> =
        new Subject<DictationTranscriberEventData>()

    private retryCount: number = 0

    constructor(deviceId?: string) {
        this.deviceId = deviceId
    }

    async start() {
        this.subject.next({ isLoading: true })

        if (!this.tokenObj) {
            this.tokenObj = await servicesService.getAzureSSTToken()
        }

        const audioConfig = this.deviceId
            ? speechsdk.AudioConfig.fromMicrophoneInput(this.deviceId)
            : speechsdk.AudioConfig.fromDefaultMicrophoneInput()

        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
            this.tokenObj.token,
            this.tokenObj.region
        )
        speechConfig.speechRecognitionLanguage = DEFAULT_LANGUAGE

        this.recognizer = new speechsdk.SpeechRecognizer(
            speechConfig,
            audioConfig
        )

        this.recognizer.recognizing = (_: any, e: any) => {
            const partialContent = e.result.text
            this.subject.next({ partialContent })
        }

        this.recognizer.recognized = (_: any, e: any) => {
            if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                const content = e.result.text
                this.subject.next({ content })
            }
        }

        this.recognizer.sessionStarted = () => {
            this.subject.next({ isRecording: true })
            this.subject.next({ isLoading: false })

            // Reset retry count on successful reconnection
            this.retryCount = 0
        }

        this.recognizer.canceled = (_: any, e: any) => {
            if (e.reason === speechsdk.CancellationReason.Error) {
                console.error(
                    'AzureSTT session cancellation error details for dictation:'
                )
                this.handleReconnection()
            } else {
                this.recognizer?.stopContinuousRecognitionAsync()
            }
            console.error({
                error: e,
            })
        }

        this.recognizer.sessionStopped = () => {
            this.recognizer?.stopContinuousRecognitionAsync()
        }

        // Start streaming audio for transcription
        this.recognizer.startContinuousRecognitionAsync()
    }

    private async handleReconnection() {
        if (this.retryCount < MAX_RETRIES) {
            this.retryCount++
            const delaySeconds = this.retryCount // 1s, 2s, 3s
            console.error(
                `Attempting to reconnect to Azure STT for dictation (Attempt ${this.retryCount}/${MAX_RETRIES}) after ${delaySeconds}s delay`
            )

            try {
                this.stop()
                // Add delay before retry
                await new Promise((resolve) =>
                    setTimeout(resolve, delaySeconds * 1000)
                )
                this.start()
            } catch (error) {
                console.error('Reconnection attempt failed:', error)
                this.handleReconnection() // Try again
            }
        } else {
            console.error(
                'Max retries reached. Unable to reconnect to Azure STT for dictation.'
            )
            toast.error(
                'Unable to reconnect to transcription service for dictation. Please try again later.'
            )
            this.stop()
            this.subject.next({ isRecording: false })
        }
    }

    stop() {
        this.recognizer?.stopContinuousRecognitionAsync()
        this.recognizer = null
        this.tokenObj = null
        this.subject.next({ isRecording: false })
    }

    getObservable() {
        return this.subject.asObservable()
    }
}

export type DictationTranscriberEventData =
    | {
          isRecording: boolean
      }
    | {
          isLoading: boolean
      }
    | {
          partialContent: string
      }
    | {
          content: string
      }
