import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import { eventBus, EventBusData } from '../../../../services/event-bus/eventBus.service'
import { TranscribedContent } from '../../../../services/event-bus/events'
import { Button } from '../../../UI/Button'
import { Loader } from '../../../UI/Loader'
import { NotepadEditor } from '../../../UI/NotepadEditor'
import { useVisitsContext } from '../../../Visits/hooks/useVisitsContext'
import { TranscriptsList } from '../../TranscriptsList'
import { Recorder } from './components/Recorder'
import { LocalContextProvider } from './hooks/useLocalContext'
import { NotepadWrapper, TranscriptsListWrapper } from './styles'

const Styles = styled.div`
    .input-wrapper {
        textarea {
            padding: 0.75rem;
        }
    }

    .transcript-list-node {
        background-color: rgba(255, 255, 255, 0.8);
    }
`

export const Record = () => {
    const { id } = useParams()
    const { isMobile } = useMediaQuery()
    const { showListView } = useVisitsContext().view
    const { areVisitsLoading: isLoading, getVisit, createTranscript } = useVisitsContext().visits
    const visitFromState = useLocation().state?.visit
    const visit = useMemo(() => (id ? getVisit(id) : visitFromState), [id, getVisit, visitFromState])

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event: EventBusData) => {
            switch (event.action) {
                // Add transcript to visit
                case TranscribedContent.action: {
                    const { visitId: _visitId, transcript } = event.payload
                    if (_visitId !== id || !id) {
                        return
                    }
                    createTranscript(id, transcript)
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [id, createTranscript])

    const transcriptsListNode = useMemo(() => {
        return visit?.transcripts ? (
            <TranscriptsListWrapper key={visit._id}>
                <TranscriptsList className="transcript-list-node h-full" transcripts={visit.transcripts} />
            </TranscriptsListWrapper>
        ) : null
    }, [visit?._id, visit?.transcripts])

    const notePadNode = useMemo(() => {
        return (
            <NotepadWrapper>
                <div className="font-medium text-lg">Notepad</div>
                <NotepadEditor key={visit?._id} className="pt-4 pb-4 h-full bg-white" visitId={visit?._id} />
            </NotepadWrapper>
        )
    }, [visit?._id])

    if (isLoading) {
        return (
            <Styles className="bg-white h-full flex justify-center items-center">
                <LoadingState />
            </Styles>
        )
    }

    if (!visit) {
        return <ErrorState />
    }

    return (
        <Styles className="bg-white h-full relative flex flex-col">
            {isMobile && (
                <>
                    <div className="absolute left-2 top-2">
                        <Button intent="text" label="" leftIconNode={<ChevronLeftIcon className="h-5 w-5 text-gray-400" />} onClick={showListView} />
                    </div>
                </>
            )}

            <LocalContextProvider key={visit._id} visitId={id} duration={visit.duration}>
                <div className="pt-16 pb-3 px-3 sm:py-8 sm:pl-10 sm:pr-16 flex flex-1 flex-col">
                    <Recorder visitId={visit._id} transcriptsListNode={transcriptsListNode} notepadNode={notePadNode} />
                </div>
            </LocalContextProvider>
        </Styles>
    )
}

const LoadingState = () => {
    return (
        <div className="flex items-center justify-center text-gray-500 my-4">
            <Loader className="mr-3" />
            <span>Loading Visit...</span>
        </div>
    )
}

const ErrorState = () => null
