/* eslint-disable @typescript-eslint/ban-ts-comment */

import Intercom from '@intercom/messenger-js-sdk'

import { servicesService } from '../services/http/services.service'
import { User } from '../services/models/User.model'

/* eslint-disable indent */
export const intercom = (() => {
    const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID!

    const init = async (user: User) => {
        try {
            const hash = await servicesService.getIntercomToken(user._id!)
            if (!hash) {
                console.error('Unable to get intercom token')
                return
            }

            Intercom({
                app_id: APP_ID,
                user_id: user._id!,
                name: user.email,
                email: user.email,
                created_at: new Date(user.createdAt).getTime(),
                user_hash: hash,
            })
        } catch (error) {
            console.error(error)
        }
    }

    return {
        init,
    }
})()
