import authAxios from './auth.axios'

const fluentAPI = process.env.REACT_APP_API
const url = `${fluentAPI}/referral/promoter`

const getPromoter = async () => {
    try {
        const { data } = await authAxios({
            method: 'GET',
            url,
        })

        return data
    } catch (error) {
        throw new Error('Promoter, failed to fetch')
    }
}

export const referralServices = {
    getPromoter,
}
