import { useState } from 'react'
import { toast } from 'react-toastify'

import { Toast as ToastContainer } from '../../UI/Toast'

interface Props {
    inviteLink ?: string
}

export const ReferralLinkCard = ({inviteLink}:Props) => {
    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        if (copied || !inviteLink) return

        navigator.clipboard.writeText(inviteLink)
        setCopied(true)
        toast.success(() => 'Invitation link copied')
        setTimeout(() => {
            setCopied(false)
            toast.dismiss()
        }, 3000)
    }

    return (
        <div className="w-full md:w-1/2 p-2">
            <div className="border flex flex-col p-4 rounded-md">
                <p className="font-bold mb-2">Copy your invite link</p>
                <div className="flex gap-2 items-center">
                    <input
                        type="text"
                        value={inviteLink}
                        readOnly
                        className="w-full border px-3 py-2 rounded text-gray-400"
                    />
                    <button
                        onClick={handleCopy}
                        disabled={!inviteLink}
                        className="bg-primary text-white px-4 py-2 rounded-md transition-all"
                    >
                        {copied ? 'Copied' : 'Copy'}
                    </button>
                </div>
            </div>

            <ToastContainer limit={2} key={'1ccer'} />
        </div>
    )
}
