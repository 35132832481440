import { useMemo } from 'react'
import styled from 'styled-components'

import { Language, LANGUAGES, LANGUAGES_LABEL } from '../../constants'
import { ListBox, ListBoxOption } from './ListBox'

interface Props {
    value: Language | undefined
    onChange(value: Language): void
}

const Styles = styled.div`
    max-height: 200px;

    .listbox-options {
        max-height: 200px;
    }
`

export const LanguagesListBox = ({ value, onChange }: Props) => {
    const optionSelected = useMemo(
        () => (value ? options.find((i) => i.value === value) : undefined),
        [value]
    )

    return (
        <Styles>
            <ListBox
                placeholder="Select language"
                optionSelected={optionSelected}
                options={options}
                onChange={(option) => onChange(option.value as Language)}
                listBoxOptionsClassname="listbox-options"
            />
        </Styles>
    )
}

const popularLanguages: Language[] = ['en-US', 'fr-CA', 'es-US']
const otherLanguages: Language[] = LANGUAGES.filter(
    (language) => !popularLanguages.includes(language)
)?.sort((a, b) => LANGUAGES_LABEL[a].localeCompare(LANGUAGES_LABEL[b]))

const options: ListBoxOption[] = [
    ...popularLanguages.map((language) => {
        return {
            value: language,
            displayNode: LANGUAGES_LABEL[language] ?? language,
        }
    }),
    {
        value: 'divider',
        displayNode: null,
        divider: true,
    },
    ...otherLanguages.map((language) => {
        return {
            value: language,
            displayNode: LANGUAGES_LABEL[language] ?? language,
        }
    }),
]
