import moment from 'moment'
import styled from 'styled-components'

import Colors from '../../../colors'
import { Lead } from './Referral'

interface Props {
    referralData?: Lead[]
}

const Styles = styled.div`
    .chip {
        display: inline-block;
        padding: 4px 6px;
        border-radius: 5px;
        font-size: 0.875rem;
        border: 1px solid transparent;

        &.signup {
            color: ${Colors.PRIMARY};
            background-color: ${Colors.PRIMARY}1C;
            transition: all 0.1s;
            &:hover {
                background-color: ${Colors.PRIMARY}14;
                border: 1px solid #406ccf3b;
                cursor: default;
            }
        }
        &.active {
            color: ${Colors.GREEN};
            background-color: ${Colors.GREEN}1C;
            transition: all 0.1s;
            &:hover {
                background-color: ${Colors.GREEN}14;
                border: 1px solid #406ccf3b;
                cursor: default;
            }
        }

        &.invitation-sent {
            color: #8e9eb496;
            background-color: #8080801a;
            border: 1px solid transparent;
            transition: all 0.1s;
            &:hover {
                color: ${Colors.NAV}66;
                border: 1px solid ${Colors.NAV}1C;
                cursor: default;
            }
        }
    }

    table {
        white-space: nowrap;
    }
`
export const ReferralsHistory = ({ referralData }: Props) => {
    if (!referralData || referralData.length === 0) return null

    return (
        <Styles className="overflow-x-auto my-3">
            <div className="inline-block min-w-full">
                <table className="table-auto min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th className="p-2" align="left">
                                Recipient email
                            </th>
                            <th className="p-2" align="left">
                                Date
                            </th>
                            <th className="p-2" align="left">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {referralData.map((referral) => (
                            <tr key={referral.id}>
                                <td className="p-2">
                                    <div className="text-sm text-gray-600">{referral.email}</div>
                                </td>
                                <td className="p-2">{moment(referral.created_at).format('MMM D, YYYY')}</td>
                                <td className="p-2">
                                    <div className={`chip ${referral.state}`}>
                                        {referral.state === 'signup' ? 'Free trial' : referral.state === 'active' ? 'Paid' : 'Invitation sent'}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Styles>
    )
}
