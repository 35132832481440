import { Subject } from 'rxjs'

import { storage } from '../lib/storage'

export interface PatientContext {
    eforms?: any[]
    cpp?: any
}

export const postMessageService = (() => {
    const subject = new Subject()
    let patientContext: PatientContext = {
        eforms: [],
        cpp: {},
    }

    window.addEventListener('message', (event) => {
        if (event?.data?.source === 'emr') {
            subject.next(event.data)

            if (event.data.action === 'patient-context') {
                try {
                    patientContext = event.data.data as PatientContext
                    storage.storeEmrContextEForms(patientContext?.eforms ?? [])
                } catch (error) {
                    console.error(
                        'Error setting received patient context',
                        error,
                        event.data
                    )
                }
            }
        }
    })

    const sendLoaded = () => {
        try {
            window?.opener?.postMessage(
                {
                    source: 'fluent',
                    action: 'loaded',
                },
                '*'
            )
        } catch (error) {
            console.error('Error sending loaded message to parent page', error)
        }
    }

    const sendFilledEForm = (eform: any, filledEFormResponse: any) => {
        try {
            window.opener.postMessage(
                {
                    source: 'fluent',
                    action: 'filled-eform',
                    data: {
                        eform,
                        response: filledEFormResponse,
                    },
                },
                '*'
            )
        } catch (error) {
            console.error(
                'Error sending filled eForm message to parent page',
                error
            )
        }
    }

    const getPatientContext = () => patientContext

    const getPatientContextEForms = () => {
        if (patientContext?.eforms) {
            return patientContext.eforms ?? []
        }
        return storage.getEmrContextEForms() ?? []
    }

    const getObservable = () => subject.asObservable()

    return {
        sendLoaded,
        sendFilledEForm,
        getPatientContext,
        getPatientContextEForms,
        getObservable,
    }
})()
