import classNames from 'classnames'
import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import styled from 'styled-components'

import { FieldError } from '../../lib/field'
import { InputErrorLabel } from './InputErrorLabel'

interface Props {
    className?: string
    inputClassName?: string
    name: string
    labelNode?: ReactNode
    rightNode?: ReactNode
    type?: string
    placeholder?: string
    multiline?: boolean
    initialValue: string
    error?: FieldError
    iconNode?: ReactNode
    debounceDelay?: number
    readonly?: boolean
    onChange?: (value: string) => void
    onChangeDebounced?: (value: string) => void
    onBlur?: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    onFocus?: (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const Styles = styled.div`
    .input {
        &-wrapper {
            border-radius: 5px;
            position: relative;
        }
    }

    textarea {
        min-height: 32px;
        max-height: 200px;
    }
`

// eslint-disable-next-line react/display-name
export const Input = forwardRef(
    (
        {
            className,
            inputClassName,
            name,
            labelNode,
            rightNode,
            type,
            placeholder,
            multiline,
            initialValue,
            error,
            iconNode,
            debounceDelay,
            readonly,
            onChange,
            onChangeDebounced,
            onBlur,
            onFocus,
            onKeyDown,
        }: Props,
        ref: any
    ) => {
        const [value, setValue] = useState<string>(initialValue ?? '')

        const hasError = useMemo(() => error && error.length > 0, [error])

        useEffect(() => setValue(initialValue ?? ''), [initialValue])

        const onChangeHandler = useCallback(
            (_value: string) => {
                setValue(_value)
                if (onChange) {
                    onChange(_value)
                }
            },
            [onChange]
        )

        // Emit debounced value
        useEffect(() => {
            if (!onChangeDebounced || value === initialValue) {
                return
            }
            const interval = setTimeout(
                () => onChangeDebounced(value),
                debounceDelay ?? 250
            )
            return () => {
                clearTimeout(interval)
            }
        }, [initialValue, value, debounceDelay, onChangeDebounced])

        return (
            <Styles className={classNames(className)}>
                {labelNode ? (
                    <label htmlFor={name} className="mb-1 font-medium text-sm">
                        {labelNode}
                    </label>
                ) : null}

                <div
                    className={classNames(
                        'input-wrapper d-flex align-items-start',
                        hasError && 'invalid-state'
                    )}
                >
                    {iconNode && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            {iconNode}
                        </div>
                    )}

                    {multiline ? (
                        <textarea
                            ref={ref}
                            className={classNames(
                                'block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-primary sm:leading-6',
                                hasError &&
                                    'ring-red focus:ring-red placeholder:text-red text-red',
                                iconNode && 'pl-10',
                                rightNode && 'pr-10',
                                inputClassName
                            )}
                            name={name}
                            placeholder={placeholder || ''}
                            value={value}
                            rows={3}
                            onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => onChangeHandler(event.target.value)}
                            onBlur={(
                                event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                if (onBlur && !readonly) {
                                    onBlur(event)
                                }
                            }}
                            onFocus={(
                                event: React.FocusEvent<HTMLTextAreaElement>
                            ) => {
                                if (onFocus) {
                                    onFocus(event)
                                }
                            }}
                            readOnly={readonly ?? false}
                        ></textarea>
                    ) : (
                        <input
                            className={classNames(
                                'block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6',
                                hasError &&
                                    'ring-red focus:ring-red placeholder:text-red text-red',
                                iconNode && 'pl-10',
                                rightNode && 'pr-10',
                                inputClassName
                            )}
                            ref={ref}
                            name={name}
                            type={type || 'text'}
                            placeholder={placeholder || ''}
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => onChangeHandler(event.target.value)}
                            onBlur={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (onBlur) {
                                    onBlur(event)
                                }
                            }}
                            onFocus={(
                                event: React.FocusEvent<HTMLInputElement>
                            ) => {
                                if (onFocus) {
                                    onFocus(event)
                                }
                            }}
                            onKeyDown={(event) => {
                                if (onKeyDown) {
                                    onKeyDown(event)
                                }
                            }}
                            readOnly={readonly ?? false}
                        />
                    )}

                    {rightNode && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            {rightNode}
                        </div>
                    )}
                </div>

                <InputErrorLabel className="mt-1" error={error} />
            </Styles>
        )
    }
)
